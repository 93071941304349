exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memory-sensoryczne-js": () => import("./../../../src/pages/memory-sensoryczne.js" /* webpackChunkName: "component---src-pages-memory-sensoryczne-js" */),
  "component---src-pages-panele-sensoryczne-js": () => import("./../../../src/pages/panele-sensoryczne.js" /* webpackChunkName: "component---src-pages-panele-sensoryczne-js" */),
  "component---src-pages-puzzle-sensoryczne-js": () => import("./../../../src/pages/puzzle-sensoryczne.js" /* webpackChunkName: "component---src-pages-puzzle-sensoryczne-js" */),
  "component---src-pages-sortery-sensoryczne-js": () => import("./../../../src/pages/sortery-sensoryczne.js" /* webpackChunkName: "component---src-pages-sortery-sensoryczne-js" */),
  "component---src-pages-warsztaty-js": () => import("./../../../src/pages/warsztaty.js" /* webpackChunkName: "component---src-pages-warsztaty-js" */)
}

